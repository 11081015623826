// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-activities-tsx": () => import("./../../../src/pages/activities.tsx" /* webpackChunkName: "component---src-pages-activities-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-game-lodge-tsx": () => import("./../../../src/pages/game-lodge.tsx" /* webpackChunkName: "component---src-pages-game-lodge-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-latest-news-tsx": () => import("./../../../src/pages/latest-news.tsx" /* webpackChunkName: "component---src-pages-latest-news-tsx" */),
  "component---src-pages-luxury-safari-tents-tsx": () => import("./../../../src/pages/luxury-safari-tents.tsx" /* webpackChunkName: "component---src-pages-luxury-safari-tents-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-rates-tsx": () => import("./../../../src/pages/rates.tsx" /* webpackChunkName: "component---src-pages-rates-tsx" */),
  "component---src-pages-special-rates-tsx": () => import("./../../../src/pages/special-rates.tsx" /* webpackChunkName: "component---src-pages-special-rates-tsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-special-jsx": () => import("./../../../src/templates/special.jsx" /* webpackChunkName: "component---src-templates-special-jsx" */)
}

